export default function() {
        let paths = [
        {match:"localhost",url:"http://localhost:3000/staging/v1/cms"},
        //{match:"localhost",url:"http://localhost:3000/production/v1/cms"},
        //      {match:"localhost",url:"https://bm49a8ffni.execute-api.eu-west-1.amazonaws.com/dev/v1/cms"},
        {match:"msnosystaging",url:"https://9z2ckenhw1.execute-api.eu-west-1.amazonaws.com/dev/v1/cms"},
        {match:"cms-staging",url:"https://27f2dzj1e8.execute-api.eu-west-1.amazonaws.com/staging/v1/cms"},
        {match:"msnosystaging2",url:"https://bm49a8ffni.execute-api.eu-west-1.amazonaws.com/dev/v1/cms"},
        {match:"cms.msnosy.app",url:"https://3uikxmzqje.execute-api.eu-west-1.amazonaws.com/production/v1/cms"},
        {match:"msnosyproduction",url:"https://3uikxmzqje.execute-api.eu-west-1.amazonaws.com/production/v1/cms"}];
        let currentpath = "";
        if (typeof window !== 'undefined') {
                for (var i = 0;i<paths.length;i++) {
                        if (location.href.indexOf(paths[i].match) != -1) {
                                currentpath = paths[i].url;
                        }
                }
        }
        
        let path = currentpath;
        
        return {
                start_page:"/sections",
                login_path:path+"/login",
                validate_path:path+"/validate",
                list_path:path+"/list",
                generate_codes_path:path+"/generateCodes",
                get_codes_path:path+"/getCodes",
                record_path:path+"/record",
                import_path:path+"/import",
                export_path:path+"/export",
                copy_campaign_points_path:path+"/copyCampaignPoints",
                action_path:path+"/action",
                user_video_path:path+"/videos/",
                
                path:path
                
        }   
}