import React, { useRef,useState,useEffect } from "react";
import styled from "styled-components";
import { media, VWSize } from "../../utils";
import gsap from "gsap/gsap-core";
import { globalHistory as history } from "@reach/router";
import MenuData from "../../config/menu.json";
import MenuSection from "../global/MenuSection";
import "../../styles/LineIcons.css";

const Wrapper = styled.div`
 line-height: 1.5;
 color: #212529;
 text-align: left;
 font-size: 14px;
 font-weight: 300;
  box-sizing: border-box;
  outline: 0;
  display: block;
  width: 220px;
  height: 100%;
  top: 0;
  z-index: 20;
  padding-top: 20px;
  background: #fff;
  box-shadow: 1px 0 20px rgba(0,0,0,.08);
  position: fixed;
`;

const Logo = styled.h3`
background-image: url("/img/logo.jpg");
width:100px;
height:100px;
background-size:contain;
margin-left: auto;
margin-right: auto;
margin-bottom:30px;
`

const MenuItemParent = styled.div`
line-height: 1.5;
list-style: none;
box-sizing: border-box;
outline: 0;
text-decoration: none;
background-color: transparent;
cursor: pointer;
overflow: hidden;
user-select: none;
-webkit-tap-highlight-color: transparent;
vertical-align: middle;
z-index: 1;
will-change: opacity,transform;
color: #8d97ad;
padding: 10px 32px 10px 15px;
align-items: center;
font-size: 14px;
font-weight: 400;
border-left: 3px solid transparent;
-webkit-transition: none;
position: relative;
`

const MenuTextRight = styled.div`
  position:absolute;
  right:30px;
  display:inline-block;
`

const MenuItemText = styled.div`
  display:inline-block; 
  width:100%;
  line-height: 1.5;
  list-style: none;
  box-sizing: border-box;
  outline: 0;
  text-decoration: none;
  background-color: transparent;
  color: #8d97ad;
  display: block;
  align-items: center;
  font-size: 14px;
  font-weight: ${props => props.active ? 800 : 400};
  padding: 7px 35px 7px 15px;
  cursor:pointer;

  :hover {
    color: #f0213b;
  }
`
const MenuTextInner = styled.span`
margin-left:10px;
cursor: pointer;
user-select: none;
-webkit-tap-highlight-color: transparent;
font-size: 14px;
font-weight: 400;
box-sizing: border-box;
outline: 0;
`

const MenuItem = styled.a`

`;

export default function Menu() {
  const [items, setItems] = useState([]);
  const [activeItem, setActiveItem] = useState(0);

  //console.log("location", location);

  function logOut(){
    localStorage.clear();
    window.location = "/";
  }

  function SetState(key,link) {
    if (link) {
      window.location.href = link;
    }
    else {
      if (key == activeItem) {
        setActiveItem(-1);
      }
      else {
        setActiveItem(key);
      }
      console.log("setting state! "+key);
    }
  }

  function GetActiveItem(){
    for (var i = 0;i<MenuData.length;i++) {
      for (var i2 = 0;i2<MenuData[i].items.length;i2++) {
        let item =MenuData[i].items[i2];
        if (window.location.href.indexOf(item.link) != -1) {
            setActiveItem(i);
        }
      }
    }
  }

  function BuildList(){
    let mitems = [];
    for (var i = 0;i<MenuData.length;i++) {
      let menuItem = MenuData[i];
      let uniquekey = i;
      let arrowicon = "lni lni-chevron-right-circle";
      let add = true;
      if (uniquekey == activeItem){
        arrowicon = "lni lni-chevron-down-circle";
      }
      
      //console.log("building menu? ",menuItem);
      if (menuItem.users) {
        //console.log("building menu",localStorage.getItem('usertype'),menuItem.users.indexOf(localStorage.getItem('usertype')));
      }
      
      if (menuItem.users && menuItem.users.indexOf(localStorage.getItem('usertype')) == -1) {
        add = false;
      }


      if (add) {
        mitems.push(<MenuItemParent key={"menuitemparent"+i} onClick={()=>{SetState(uniquekey,menuItem.link)}}><MenuItemText  key={"menuitemtext2"+i}><i className={"lni " + menuItem.icon} /><MenuTextInner>{menuItem.name}</MenuTextInner><MenuTextRight><i className={arrowicon} /></MenuTextRight></MenuItemText>{uniquekey == activeItem && <MenuSection menuitems={menuItem.items}></MenuSection>}</MenuItemParent>);
      }
    }
    
    i++;
  
    mitems.push(<MenuItemParent key={"menuitemparent"+i} onClick={logOut}><MenuItemText  key={"menuitemtext2"+i}><i className={"lni  lni-exit"} /><MenuTextInner>Log out</MenuTextInner></MenuItemText></MenuItemParent>);
    setItems(mitems);
  }
  
  useEffect(() => {
    GetActiveItem();
    BuildList();
    
  }, []);

  useEffect(() => {
    
    BuildList();
    
  }, [activeItem]);


  return (
    <Wrapper>
      <Logo></Logo>
    {
      items
    }
    </Wrapper>
  );
}
