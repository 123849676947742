import React, { useRef,useState,useEffect } from "react";
import styled from "styled-components";
import { media, VWSize } from "../../utils";
import gsap from "gsap/gsap-core";
import { globalHistory as history } from "@reach/router";
import MenuData from "../../config/menu.json";

const Wrapper = styled.div`
 line-height: 1.5;
 color: #212529;
 text-align: left;
 font-size: 14px;
 font-weight: 300;
  box-sizing: border-box;
  outline: 0;
  display: block;
  width: 220px;
  height: 100%;
  top: 0;
  z-index: 20;
  padding-top: 20px;
  background: #fff;
  box-shadow: 1px 0 20px rgba(0,0,0,.08);
  position: fixed;
`;

const Logo = styled.h3`
background-image: url("/img/logo.jpg");
width:100px;
height:100px;
background-size:contain;
margin-left: auto;
margin-right: auto;
margin-bottom:30px;
`

const MenuItem = styled.a`
  width:100%;
  line-height: 1.5;
  list-style: none;
  box-sizing: border-box;
  outline: 0;
  text-decoration: none;
  background-color: transparent;
  color: #8d97ad;
  display: block;
  align-items: center;
  font-size: 14px;
  font-weight: ${props => props.active ? 800 : 400};
  padding: 7px 0px 7px 40px;
  cursor:pointer;

  :hover {
    color: #f0213b;
  }
`;

export default function MenuSection({menuitems}) {
  const [items, setItems] = useState([]);

  //console.log("location", location);

  function clicked(it) {
    window.location = it.link;
  }

  function logOut(){
    localStorage.clear();
    window.location = "/";
  }
  
  useEffect(() => {
    console.log('menudata',MenuData);
    console.log("length",MenuData.length);

    let mitems = [];
    for (var i = 0;i<menuitems.length;i++) {
      let menuItem = menuitems[i];
      let add = true;
      console.log("section",menuItem);

      if (menuItem.users) {

        console.log("menuitem",menuItem,menuItem.users,localStorage.getItem('usertype'),menuItem.users.indexOf(localStorage.getItem('usertype')));
      }

      if (menuItem.users && menuItem.users.indexOf(localStorage.getItem('usertype')) == -1) {
        add = false;
      }

      if (window.location.href.indexOf(menuItem.link) != -1) {
        menuItem.active=true;
      }
      
      if (add) {
        mitems.push(<MenuItem onClick={() => clicked(menuItem)} key={"menuitem"+i} active={menuItem.active}>{menuItem.name}</MenuItem>);
      }
    }
    
    i++;
    setItems(mitems);
  }, []);


  return (
      items
  );
}
